@font-face {
  font-family: 'Kiroku-Sans-Light';
  src: url('./fontFiles/Kiroku-Sans-Light.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Kiroku-Sans-Regular';
  src: url('./fontFiles/Kiroku-Sans-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Kiroku-Sans-Medium';
  src: url('./fontFiles/Kiroku-Sans-Medium.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Kiroku-Sans-Bold';
  src: url('./fontFiles/Kiroku-Sans-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Kiroku-Sans-Black';
  src: url('./fontFiles/Kiroku-Sans-Black.ttf') format('truetype');
  font-style: normal;
}

div {
  display: block;
}

.nav {
  padding-left: 24px;
  padding-top: 24px;
}

.nav img {
  width: 100px;
}

.main-title {
  padding: 0 0 9px;
  opacity: 1;
  text-align: center;
  transition: opacity .2s ease-in;
}

.main-title h1 {
  width: 600px;
  font-size: 40px;
  line-height: 1.1;
  font-weight: 600;
  letter-spacing: 0;
  font-family: Kiroku-Sans-Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: center;
  margin: 0px auto 9px;
}

@media only screen and (max-width: 650px) {
  .main-title h1 {
    font-size: 32px;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: normal;
    width: 320px;
  }
}

.workflow-container {
  margin-left: auto;
  margin-right: auto;
  width: 980px;
  transition: opacity .5s;
  padding-bottom: 62px;
  opacity: 1;
  transition-delay: .3s;
}


@media only screen and (max-width: 650px) {
  .workflow-container {
    width: 100%;
  }
}

.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 48px;
  grid-row-gap: 16px;
  padding: 0 26px 0 29px;
  height: 100vh;
}

@media only screen and (max-width: 650px) {
  .parent {
    display: inline-block;
    padding: 0px;
    width: 80vw;
    margin-left: 10vw;
    margin-right: 10vw;
  }
}

.image-wrapper img {
  height: auto;
  width: 226px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-top: 24px;
  padding-bottom: 24px;
}

h3 {
  font-size: 24px;
  line-height: 1.16667;
  font-weight: normal;
  letter-spacing: -.009em;
  font-family: Kiroku-Sans-Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
  margin: 0;
  word-wrap: break-word;
  text-align: center;
}


.text-wrapper {
  max-width: 360px;
  margin: auto;
}

.powered {
  text-align: center;
  font-size: 18px !important;
}

.text-wrapper p {
  font-size: 14px;
  line-height: 1.42861;
  font-weight: 400;
  letter-spacing: -.016em;
  font-family: Kiroku-Sans-Medium, Helvetica Neue, Helvetica, Arial, sans-serif;
  margin: 21px 0 0;
  list-style: none;
}

.button-container {
  padding: 18px 0 23px;
  text-align: center;
}

button {
  width: 100%;
  padding: 12px 20px;
  cursor: pointer;
  background: #B98F47;
  color: white;
  font-size: 14px;
  line-height: 1.42861;
  font-weight: 400;
  letter-spacing: -.016em;
  font-family: Kiroku-Sans-Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
  border: none;
  border-radius: 4px;
  max-width: 360px;
}

button:hover {
  background: #C69136;
}

.kiroku-logo {
  font-family: Kiroku-Sans-Black;
  letter-spacing: -.04em;
  color: #007A87;
}

.sup {
  vertical-align: super;
  font-size: 14px;
}

.sup-small {
  vertical-align: super;
  font-size: 10px;
}